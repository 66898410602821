<template>
  <!-- 大会直播 -->
  <div class="live-schedule">
    <Title :title="title" :description="description"></Title>
    <div class="giac-content" :class="ispc?'pc':'mob'" v-if="Object.keys(groups).length == 0">
      <p class="load_word">{{load_word}}</p>
    </div>
    <slot v-else>
      <!-- pc -->
      <div class="giac-content pc" v-if="ispc">
        <div class="list">
          <!-- 短标题+无开幕式： -->
          <div class="list-con">
            <div class="box" 
                v-for="(item, index) in groups" :key="'live_group_' + index"
                :style="`width:${100/(Object.keys(groups).length)}%;`" 
            > <div 
                class="litem" 
                :class="group == index-1 ? 'act' : ''" 
                @click="setGroup($event, index-1, 'pc')" 
              > <h1>{{item.groupName}}</h1>
              </div>
              <div 
                class="litem" 
                :class="group == index-1 ? 'act' : ''" 
              > <p v-if="!getTime_('group_start',index-1) && getTime_('group_end',index-1)">正在直播</p>
                <p v-else-if="!getTime_('end')">直播结束</p>
                <p v-else>即将开始</p>
              </div>
            </div>
          </div>
        </div>
        <div class="courses">
          <div 
            class="livebtn act" 
            v-if="themeList[group] && themeList[group].length>0 && !getTime_('group_start',group) && getTime_('group_end',group)" 
            @click="toLive(theTime>=endTime?(endTime/1000):(startTime/1000), group+1)" 
          >直播中，点击进入直播间
          </div>
          <slot v-for="(item, index) in themeList[group]" >
            <div 
              class="line" 
              v-if="item.venueName!='开幕式'"
              :key="'live_theme_' + index" 
            >
              <div class="left">
                <div class="mark" v-if="isTime(item.date*1000, item.times.split(',')[0].split('-')[0], item.times.split(',')[item.times.split(',').length-1].split('-')[1])">
                  <img src="../assets/img/live_start.png" alt="">
                  <!-- <p class="await">即将开始</p>
                  <p class="start">直播中</p>
                  <p class="end">已结束</p> -->
                </div>
                <div class="box">
                  <h1>{{ item.venueName }}</h1>
                  <p>出品人：{{ item.name }}</p>
                  <p class="time">{{global.formatTime(item.date,'Y.M.D')}} {{global.getWeek(item.date)}}</p>
                </div>
              </div>
              <div class="right">
                <div 
                  class="course" 
                  v-for="(titem, cindex) in item.times.split(',')" 
                  :key="'live_course_' + index + '_' + cindex" 
                  style="width: 25%;" 
                >
                  <!-- :style="`width:${100 / item.times.split(',').length}%;`" -->
                  <!-- {{titem.split('-')[0]}} {{titem.split('-')[1]}} -->
                  <slot v-for="(citem, cindex) in item.schedulingCourse">
                    <div 
                      class="box" 
                      v-if="citem && citem.startTime == titem.split('-')[0]" 
                      :key="'live_schedulingCourse_' + index + '_' + cindex" 
                    >
                      <h2 @click="toLive(citem.date, group+1)">
                        《{{ citem.courseTitle }}》
                      </h2>
                      <p class="time"><i class="glyphicon glyphicon-time"></i> {{citem.startTime}}-{{citem.endTime}}</p>
                      <p @click="toLive(citem.date, group+1)">
                        {{ citem.name }} {{ citem.company }} {{ citem.position }}
                      </p>
                    </div>
                  </slot>
                  <div class="box none">
                    <h1>敬请期待</h1>
                  </div>
                </div>
                <div 
                  class="course" 
                  v-if="item.times.split(',').length < 4" 
                  style="width: 25%;" 
                >
                  <div class="box none"></div>
                </div>
              </div>
            </div>
          </slot>
        </div>
      </div>
      <!-- mob 767px -->
      <div class="giac-content mob" id="live_groups" v-else>
        <div class="top" v-if="false">
          <div class="titem" 
              :class="group==index-1 ? 'act' : ''"
              v-for="(item, index) in groups" 
              :key="'group_mob_'+index"
              :style="'width:'+(parseInt(100/(Object.keys(groups).length/2))-1)+'%;'"
              @click="setGroup($event,index-1,'mob')"
          >
            <div class="box">
              <h1>{{item.groupName}}</h1>
              <p class="act" v-if="!getTime_('group_start',index-1) && getTime_('group_end',index-1)">正在直播</p>
              <p v-else-if="!getTime_('end')">直播结束</p>
              <p v-else>即将开始</p>
            </div>
          </div>
        </div>
        <!-- 标题文字对齐 -->
        <div class="top">
          <table 
            v-for="line in Math.ceil(Object.keys(groups).length/menuCount)" 
            :key="'groups_table_'+line"
            style="width: 100vw;margin-left: 0;margin-right: 0;"
          >
            <!-- :style="'width:'+swidth+20+'px;'"  -->
            <tr v-for="tr in 2" :key="'groups_table_'+line+'_tr_'+tr">
              <td v-for="num in menuCount" 
                :key="'group_table_'+line+'_tr1_'+num"
                :class="group==(line-1)*menuCount+num-1 ? 'act' : ''" 
                :style="`width:${(100/menuCount)}vw;`" 
                @click="setGroup($event,(line-1)*menuCount+num-1,'mob')"
              >
                <div class="box" :style="groups[(line-1)*menuCount+num] ? 'background-color:#FFFFFF;' : 'background-color:transparent;'">
                  <h1 v-if="tr==1">{{groups[(line-1)*menuCount+num].groupName}}</h1>
                  <slot v-else-if="tr==2">
                    <slot v-if="line==1 && num==1">
                      <p class="act" v-if="!getTime_('group_start',(line-1)*menuCount+num-1) && getTime_('group_end',(line-1)*menuCount+num-1)">正在直播</p>
                      <p v-else-if="getTime_('start',0)">即将开始</p>
                      <p v-else>直播结束</p>
                    </slot>
                    <slot v-else>
                      <p class="act" v-if="!getTime_('group_start',(line-1)*menuCount+num-1) && getTime_('group_end',(line-1)*menuCount+num-1)">正在直播</p>
                      <p v-else-if="!getTime_('end')">直播结束</p>
                      <p v-else>即将开始</p>
                    </slot>
                  </slot>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <!-- collapse -->
        <div class="groups" v-if="theme_cli_mob" :key="'groups_mob_'+theme_cli_mob">
          <div
            class="group"
            :class="['group_' + index, theme[group] == index ? 'act' : '']"
            v-for="(item, index) in themeList[group]"
            :key="'live_group_mob_' + index"
          >
            <!-- head -->
            <div class="group_head" 
                :class="theme[group] == index ? 'act' : ''"
                :id="'live_header_'+index" 
                @click.prevent="theme[group]!=index ? theme[group]=index : theme[group]=-1, $forceUpdate()"
                :data-target="'#live_group_list_' + index" 
                data-toggle="collapse"
                aria-expanded="false"
                :aria-controls="'live_group_list_' + index"
            >
              <h1>{{item.venueName}}</h1>
              <!-- <h2>出品人：{{item.producers}}</h2> -->
              <p class="act" 
                 v-if="!getTime_('theme_start',index) && getTime_('theme_end',index)"
                 @click="toLive(theTime>=endTime?(endTime/1000):(startTime/1000), group+1)" 
              >
                正在直播
              </p>
              <p v-else-if="!getTime_('theme_end',index)">直播结束</p>
              <p v-else>即将开始</p>
              <span
                :class="theme[group] == index ? 'open' : 'close'"
              ></span>
            </div>
            <!-- body -->
            <div class="collapse" :id="'live_group_list_' + index" data-parent="#live_groups" :aria-labelledby="'live_header_'+index">
              <div
                class="group_detail"
                v-for="(titem, tindex) in item.schedulingCourse"
                :key="'live_group_mob_' + index + '_' + tindex"
              >
                <!-- {{titem}}<br><br>{{group}} -->
                <div class="group_course" @click="toLive(titem.date, group+1)">
                  <h2>{{titem.startTime}}-{{titem.endTime}}</h2>
                  <h1>{{titem.courseTitle}}</h1>
                  <p><span>{{titem.name}}</span> {{titem.compony}} {{titem.position}}</p>
                  <span class="act" v-if="!getTime_('course_start',index,tindex) && getTime_('course_end',index,tindex)">正在直播</span>
                  <span v-else-if="!getTime_('course_end',index,tindex)">直播结束</span>
                  <span v-else>即将开始</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="giac-content mob" id="live_themes" v-if="false">
        <!-- collapse -->
        <div
          class="theme"
          :class="['theme_' + index, group == index ? 'act' : '']"
          v-for="(item, index) in themeList"
          :key="'live_theme_mob_' + index"
        >
          <!-- head -->
          <div class="theme_head" 
              :class="group == index ? 'act' : ''"
              :id="'live_header_'+index" 
              @click.prevent="setGroup($event,index,'mob',tindex)"
              :data-target="'#live_theme_list_' + index" 
              data-toggle="collapse"
              aria-expanded="false"
              :aria-controls="'live_theme_list_' + index"
          >
            {{groups[index+1]&&groups[index+1].groupName ? groups[index+1].groupName : ""}}
            <p class="act" v-if="!getTime_('group_start',index) && getTime_('group_end',index)">正在直播</p>
            <p v-else-if="getTime_('end')">直播结束</p>
            <p v-else>即将开始</p>
            <span
              :class="group == index ? 'open' : 'close'"
            ></span>
          </div>
          <!-- body -->
          <div class="collapse" :id="'live_theme_list_' + index" data-parent="#live_themes" :aria-labelledby="'live_header_'+index">
            <div
              class="theme_detail"
              v-for="(titem, tindex) in item"
              :key="'live_theme_mob_' + index + '_' + tindex"
            >
              <!-- title -->
              <div class="theme_title"
                  :id="'live_header_'+index+'_'+tindex" 
                  :data-target="'#live_theme_list_' + index + '_' + tindex" 
                  data-toggle="collapse"
                  aria-expanded="false"
                  :aria-controls="'live_theme_list_' + index + '_' + tindex"
              >
                {{ titem.venueName }}
              </div>
              <!-- list -->
              <div class="collapse" :id="'live_theme_list_' + index + '_' + tindex" :data-parent="'#live_theme_list_' + index" :aria-labelledby="'live_header_'+index+'_'+tindex">
                <p v-if="!titem.schedulingCourse||titem.schedulingCourse.length==0" class="no_course">主题下暂无课程</p>
                <slot v-else>
                  <div class="theme_course"
                      v-for="(citem,cindex) in titem.schedulingCourse"
                      :key="'theme_course_' + index + '_' + tindex + '_' + cindex"
                      @click="toLive(citem.date, index+1)"
                  >
                    <h3>{{citem.courseTitle}}</h3>
                    <p>{{citem.name}} {{citem.company}} {{citem.position}}</p>
                  </div>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import {getScheduleInfo, getSpeechInfoNew, sid} from "@/utils/api.js";

export default {
  name: "LiveSchedule",
  components: {
    Title: ()=>import("@/components/Title"),
  },
  data() {
    return {
      title: "大会直播",
      description: "",
      load_word: "加载中...",
      swidth: window.innerWidth,
      ispc: true,
      token: "",
      groups: {},
      themeList: [],
      group: 0,
      theme: [],
      menuCount: 3,
      theme_cli_pc: false,
      theme_cli_mob: false,
      startTime: "",
      endTime: "",
      theTime: new Date().getTime(),
      timestamp: 24*60*60*1000,
    };
  },
  mounted() {
    this.token = localStorage.getItem("token")?localStorage.getItem("token"):"";
    this.initsize();
    this.getGroups();
    this.getTheme();
  },
  methods: {
    initsize: function() {
      this.swidth = window.innerWidth;
      if (this.swidth > 767) {
        this.ispc = true;
        this.group = 0;
        this.theme_cli_pc = true;
        this.theme_cli_mob = false;
      } else {
        this.ispc = false;
        // this.group = -1;
        this.group = 0;
        this.theme_cli_pc = false;
        this.theme_cli_mob = true;
      }
      // console.log(this.ispc, this.group);
      this.setresize();
    },
    setresize: function() {
      // this.swidth = window.innerWidth;
      window.addEventListener("resize", () => {
        let swidth = window.innerWidth;
        // pc->mob || mob->mob
        if (
          (this.swidth > 767 && swidth <= 767) ||
          (this.swidth <= 767 && swidth <= 767)
        ) {
          this.ispc = false;
          this.theme_cli_pc = false;
          !this.theme_cli_mob ? this.group=0 : "";
          // !this.theme_cli_mob ? this.group=-1 : "";
          this.theme_cli_mob = true;
        }
        // mob->pc || pc->pc
        if (
          (this.swidth <= 767 && swidth > 767) ||
          (this.swidth > 767 && swidth > 767)
        ) {
          this.ispc = true;
          this.theme_cli_mob = false;
          !this.theme_cli_pc ? this.group=0 : "";
          this.theme_cli_pc = true;
        }
        // console.log(this.swidth, swidth, this.ispc, this.group, this.theme_cli_pc, this.theme_cli_mob);
        this.$forceUpdate();
      });
    },
    // 
    getGroups: function () {
      this.$http
        .jsonp(getScheduleInfo + sid(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          // console.log(res);
          this.groups = {};
          if (res.errno == 0) {
            this.startTime = Object.keys(res.data.datas)[0]*1000;
            this.endTime = Object.keys(res.data.datas)[1]*1000;
            let finishing = {...res.data.finishing}
            for(let day in finishing){
              for(let group in finishing[day]){
                // console.log(finishing[day][group].groupId, finishing[day][group].groupName);
                if(!this.groups[finishing[day][group].groupId]){
                  this.groups[finishing[day][group].groupId]=finishing[day][group];
                }
              }
            }
            // console.log(this.groups);
            this.load_word = Object.keys(this.groups).length>0 ? "加载中..." : "暂无直播";
          }
        });
    },
    getTheme: function() {
      this.$http
        .jsonp(getSpeechInfoNew + sid(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          // console.log(res.data);
          this.theme = [];
          if (res.errno == 0) {
            let id_arr = [];
            for(let obj of res.data) {
              id_arr.indexOf(obj.groupId)<0 ? id_arr.push(obj.groupId) : "";
            }
            id_arr.sort();
            this.themeList = this.splitTheme(res.data, id_arr);
            // console.log(this.themeList);
            for(let item in this.themeList){
              this.theme.push(-1);
              // console.log(item);
            }
          }
        });
    },
    splitTheme(themeList, id_arr) {
      const list = [];
      for (let i = 0; i < id_arr.length; i++) {
        list.push([]);
      }
      for (let item of themeList) {
        // list.push(themeList.slice(i, (i += num)));
        item.schedulingCourse ? item.schedulingCourse.sort(this.global.toSortKey("startTime",1,"string")) : "";
        item.venueName!="开幕式" ? list[item.groupId-1].push(item) : "";
      }
      return list;
    },
    //
    setGroup: function(e, index, type, tindex) {
      // e.stopPropagation();
      let _this = this;
      let g = this.group;
      if (type == "pc") {
        this.group = index;
        this.theme_cli_pc = true;
        this.theme_cli_mob = false;
      } else if (type == "mob") {
        // console.log("mob",g,this.group,index,this.theme[g],this.theme[index])
        this.theme_cli_pc = false;
        this.theme_cli_mob = false;
        this.theme[g] = -1;
        this.$forceUpdate();
        // this.group = index;
        // this.theme_cli_mob = true;
        setTimeout(()=>{
          _this.group = index;
          _this.theme_cli_mob = true;
          _this.$forceUpdate();
        },20);
        this.$forceUpdate();
      }
      // console.log('cli ', this.group, this.theme_cli_pc, this.theme_cli_mob);
    },
    toLive: function(date, group) {
      // console.log(date, group);
      this.$router.push({path:"/2022sz/live", name:"TwoSZLive", query:{groupid:group, time:date.toString()}});
      // this.$router.push({path:"/2022sz/live", name:"TwoSZLive", query:{groupid:group, time:date, token:this.token}});
    },
    toCourse: function(_cid) {
      this.$router.push({
        path: "/2022sz/course",
        name: "TwoSZCourse",
        query: { id: _cid }
      });
    },
    // 时间戳->时间(xx:xx)，比较
    isTime: function(date, time1, time2) {
      let t0 = this.theTime;
      let t1 = this.startTime;
      let t2 = this.endTime;
      let hm = this.formatTime(new Date(t0).getHours(), new Date(t0).getMinutes());
      // console.log(date, time1, time2, hm);
      // console.log(this.theTime, this.startTime, this.endTime);
      if(date){
        if(date == t1){
          if(this.isDate(t0, t1) && !this.isDate(t0, t2)){
            return (time1<=hm && time2>hm) ? true : false;
            // (time1<=hm && time2>hm) ? (()=>{return true})() : "";
          }
        } else if(date == t2){
          if(this.isDate(t0, t2) && !this.isDate(t0, t2+this.timestamp)){
            return (time1<=hm && time2>hm) ? true : false;
            // (time1<=hm && time2>hm) ? (()=>{return true})() : "";
          }
        }
      } else {
        if(this.isDate(t0, t1) && !this.isDate(t0, t2+this.timestamp)){
          return (time1<=hm && time2>hm) ? true : false;
        }
      }
      return false;
    },
    isDate: function(date1, date2) {
      return date1>date2 ? true : false;
    },
    formatTime: function(hour, minute) {
      let h = hour<10 ? "0"+hour: hour;
      let m = minute<10 ? "0"+minute: minute;
      let res = h + ":" + m;
      return res;
    },
    // 获取指定内容时间戳 (大会、主题、专题、课程) 并与当前时间时间戳比较，theTime<res?true:false;
    getTime_: function(type, index1, index2) {
      /* type 类型   index1 主题/专题索引   index2 课程索引 */
      return this.theTime<this.setTime_(type, index1, index2) ? true : false;
    },
    setTime_: function(type, index1, index2) {
      // console.log(this.themeList);
      // console.log(this.theTime, this.startTime, this.endTime);
      let time=0, theme, len, times, tlen, h, m, i1, i2, i3, i4;
      if(this.themeList.length>0){
        // 大会开始/结束时间
        if(type=="start" || type=="end"){
          i1 = type=="start" ? 0 : this.themeList.length-1;
          theme = this.themeList[i1];
          len = theme.length;
          i2 = type=="start" ? 0 : len-1;
          times = theme[i2].times.split(',');
          tlen = times.length;
          i3 = type=="start" ? 0 : tlen-1;
          i4 = type=="start" ? 0 : 1;
          h = parseInt(times[i3].split('-')[i4].split(':')[0]);
          m = parseInt(times[i3].split('-')[i4].split(':')[1]);
          time = (type=="start"?this.startTime:this.endTime) + h*60*60*1000 + m*60*1000;
        } else {
          if(type == "group_start" || type == "group_end"){
            // 主题开始/结束时间
            theme = this.themeList[index1];
            len = theme.length;
            i1 = type=="group_start" ? (this.theTime>this.endTime?(len-2):0) : len-1;
            times = theme[i1].times.split(',');
            tlen = times.length;
            i2 = type=="group_start" ? 0 : tlen-1;
            i3 = type=="group_start" ? 0 : 1;
            h = parseInt(times[i2].split('-')[i3].split(':')[0]);
            m = parseInt(times[i2].split('-')[i3].split(':')[1]);
            time = (this.theTime>this.endTime?this.endTime:this.startTime) + h*60*60*1000 + m*60*1000;
          } else {
            theme = this.themeList[this.group][index1];
            // 专题开始/结束时间
            if(type == "theme_start" || type == "theme_end"){
              times = theme.times.split(',');
              tlen = times.length;
              i1 = type=="theme_start" ? 0 : tlen-1;
              i2 = type=="theme_start" ? 0 : 1;
              h = parseInt(times[i1].split('-')[i2].split(':')[0]);
              m = parseInt(times[i1].split('-')[i2].split(':')[1]);
              time = theme.date*1000 + h*60*60*1000 + m*60*1000;
            }
            // 课程开始/结束时间
            if(type == "course_start" || type == "course_end"){
              i1 = type.split("course_")[1] + "Time";
              h = parseInt(theme.schedulingCourse[index2][i1].split(':')[0]);
              m = parseInt(theme.schedulingCourse[index2][i1].split(':')[1]);
              time = theme.schedulingCourse[index2].date*1000 + h*60*60*1000 + m*60*1000;
            }
          }
        }
      }
      // console.log(time);
      return time;
    },
  }
};
</script>

<style lang="scss">
.live-schedule {
  background: #fafbfc;
  padding: 0 15px;
  padding-bottom: 90px;
  h1, h2, p {
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
  }
  .giac-content {
    width: 100%;
    max-width: 1345px;
    margin: 0 auto;
    .load_word {
      width: 100%;
      padding: 40px 10px;
      font-size: 18px;
      font-family: Source Han Sans CN Normal;
      font-weight: 400;
      color: #999999;
      text-align: center;
    }
    .list .list-con, 
    .courses .line, 
    .courses .line .right {
      display: flex;
      flex-wrap: nowrap;
    }
    .list {
      width: 100%;
      padding: 0 15px;
      // padding: 10px 15px;
      background: #ffffff;
      border: 1px solid #FFFFFF;
      table.list-con {
        height: 100%;
        background: linear-gradient(0deg, #FFFFFF 0%, #FFFFFF 10%, #D1D1D1 51%, #FFFFFF 90%, #FFFFFF 100%);
        tr {
          height: 100%;
          background: transparent;
          td:not(:nth-of-type(1)) {
            padding-left: 1px;
          }
          td {
            height: 100%;
            background: transparent;
            * {
              background: #FFFFFF;
            }
          }
        }
        // tr:nth-of-type(1) {
        //   td:not(:nth-of-type(1)) .head {
        //     border-left: 1px solid;
        //     border-image: linear-gradient(180deg, #FFFFFF 0%, #D1D1D1 100%) 1;
        //   }
        // }
        // tr:nth-of-type(2) {
        //   td:not(:nth-of-type(1)) p {
        //     border-left: 1px solid;
        //     border-image: linear-gradient(0deg, #FFFFFF 0%, #D1D1D1 100%) 1;
        //   }
        // }
        .litem {
          // width: 20%;
          vertical-align: top;
          .head {
            width: 100%;
            height: 100%;
            padding: 0 10px;
          }
          h1 {
            height: 100%;
            // text-align: left;
          }
        }
      }
      .list-con {
        width: 100%;
        max-width: 1205px;
        margin: 0 auto;
        justify-content: space-evenly;
        display: table;
        .box {
          width: 20%;
          padding: 10px 10px;
          display: table-cell;
        }
        .box:not(:nth-of-type(1)) {
          border-left: 1px solid;
          border-image: linear-gradient(0deg, #FFFFFF 0%, #FFFFFF 25%, #D1D1D1 51%, #FFFFFF 75%, #FFFFFF 100%) 1;
        }
        .litem {
          width: 100%;
          h1 {
            width: 100%;
            padding: 0 10px;
            max-width: 123px;
            margin: 0 auto;
            margin-bottom: 0;
            line-height: 32px;
            font-size: 20px;
            font-family: Source Han Sans CN Medium;
            font-weight: 500;
            color: #242424;
            border-radius: 15px;
            text-align: center;
            cursor: pointer;
          }
          p {
            margin-top: 2px;
            // margin-bottom: 0;
            // padding-top: 10px;
            line-height: 18px;
            font-size: 18px;
            font-family: Source Han Sans CN Normal;
            font-weight: 400;
            color: #999999;
            text-align: center;
          }
        }
        .litem.act {
          h1 {
            color: #FFFFFF;
            background: #E10000;
          }
          p {
            color: #E10000;
          }
        }
      }
    }
    .courses {
      width: 100%;
      margin-top: 12px;
      background: #ffffff;
      padding: 45px 15px 50px 15px;
      .line {
        width: 100%;
        max-width: 1205px;
        margin: 0 auto;
        margin-bottom: 15px;
        background: #FAFDFF;
        .left {
          width: 255px;
          min-width: 255px;
          padding: 30px 0;
          background: #F3F5FB;
          display: table;
          vertical-align: middle;
          position: relative;
          .mark {
            top: 0;
            right: 0;
            position: absolute;
            background: transparent;
          }
          .box {
            display: table-cell;
            vertical-align: middle;
            h1 {
              line-height: 21px;
              font-size: 21px;
              font-family: PingFang SC Medium;
              font-weight: 500;
              color: #00081A;
              text-align: center;
            }
            p {
              margin-top: 10px;
              line-height: 18px;
              font-size: 18px;
              font-family: PingFang SC Regular;
              font-weight: 400;
              color: #666666;
              text-align: center;
            }
            p.time {
              font-size: 14px;
              font-family: PingFang SC Medium;
            }
          }
        }
        .right {
          width: 100%;
          padding: 30px 0;
          .course:not(:nth-of-type(1)) {
            border-left: 1px solid;
            border-image: linear-gradient(0deg, #FFFFFF 0%, #D1D1D1 51%, #FFFFFF 100%) 1;
          }
          .course {
            width: 25%;
            padding: 0 10px;
            position: relative;
            .box:not(.none) {
              cursor: pointer;
            }
            .box {
              width: 100%;
              height: 100%;
              max-width: 230px;
              margin: 0 auto;
              display: table;
              position: relative;
              z-index: 2;
              background: #FAFDFF;
              h2 {
                line-height: 22px;
                font-size: 16px;
                font-family: PingFang SC Medium;
                font-weight: 500;
                color: #3B44B9;
                text-align: left;
              }
              p {
                margin-top: 10px;
                line-height: 22px;
                font-size: 14px;
                font-family: PingFang SC Regular;
                font-weight: 400;
                color: #999999;
                text-align: left;
              }
            }
            .box.none {
              max-width: 100%;
              top: 0;
              left: 0;
              z-index: 1;
              position: absolute;
              h1 {
                font-size: 20px;
                font-family: PingFang SC Medium;
                font-weight: 500;
                color: #3B44B9;
                text-align: center;
                display: table-cell;
                vertical-align: middle;
              }
            }
          }
        }
      }
      .livebtn {
        width: 100%;
        max-width: 310px;
        height: 50px;
        line-height: 50px;
        font-size: 22px;
        font-family: Source Han Sans CN Normal;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        border-radius: 8px;
        margin: 0 auto;
        margin-bottom: 45px;
        // margin-top: 45px;
        cursor: pointer;
      }
      .livebtn.act {
        background: #C90E17;
      }
    }
  }
  .giac-content.mob#live_themes {
    width: 100%;
    // margin-top: 15px;
    border: 2px solid #f7f9fe;
    .theme {
      width: 100%;
      .theme_head.act {
        // color: #2630b2;
        color: #3332C8;
      }
      .theme_head {
        width: 100%;
        // padding: 17px 20px;
        padding: 20px;
        padding-right: 32px;
        // line-height: 14px;
        // font-size: 14px;
        // font-family: PingFang SC Medium;
        // font-weight: 500;
        line-height: 20px;
        font-size: 20px;
        font-family: Source Han Sans CN Bold;
        font-weight: 700;
        color: #999999;
        border: 2px solid #f7f9fe;
        background: #ffffff;
        position: relative;
        z-index: 20;
        cursor: pointer;
        p {
          line-height: 13px;
          font-size: 13px;
          font-family: PingFang SC Medium;
          font-weight: 500;
          color: #cccccc;
          bottom: 20px;
          right: 40px;
          position: absolute;
        }
        p.act {
          color: #FF0000;
        }
        span {
          // width: 9px;
          // height: 9px;
          // top: 17px;
          width: 10px;
          height: 10px;
          top: 23px;
          right: 20px;
          position: absolute;
          display: inline-block;
          border-top: 2px solid #2630b2;
          border-left: 2px solid #2630b2;
          transform: rotate(-135deg);
        }
        span.open,
        span.close {
          opacity: 1;
        }
        span.open {
          // top: 21px;
          top: 28px;
          transform: rotate(45deg);
        }
        span.close {
          border-color: #999999;
        }
      }
      .theme_detail {
        width: 100%;
        >div {
          width: 100%;
        }
        .theme_title {
          width: 100%;
          padding: 0 20px;
          // line-height: 40px;
          // font-size: 14px;
          // font-family: PingFang SC Medium;
          // font-weight: 500;
          line-height: 50px;
          font-size: 18px;
          font-family: Source Han Sans CN Bold;
          font-weight: 700;
          color: #FFFFFF;
          text-align: center;
          // background: #2630B2;
          background: #3332C8;
          border: 1px solid #FFFFFF;
          position: relative;
          z-index: 15;
        }
        .theme_course {
          width: 100%;
          padding: 20px;
          padding-bottom: 25px;
          border: 2px solid #FFFFFF;
          background: linear-gradient(165deg, #F6F8FC 0%, #FFFFFF 100%);
          position: relative;
          z-index: 10;
          h3 {
            margin-bottom: 0;
            // line-height: 20px;
            // font-size: 14px;
            line-height: 30px;
            font-size: 18px;
            font-family: PingFang SC Medium;
            font-weight: 500;
            color: #3B44B9;
          }
        }
        .theme_course p, .no_course {
          margin-bottom: 0;
          margin-top: 10px;
          // line-height: 13px;
          // font-size: 13px;
          line-height: 16px;
          font-size: 16px;
          font-family: PingFang SC;
          // font-family: PingFang SC Regular;
          font-weight: 500;
          color: #999999;
        }
        .no_course {
          text-align: center;
          padding-bottom: 20px;
        }
      }
    }
  }
  .giac-content.mob#live_groups {
    .top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .titem {
        padding: 5px 0;
        margin-bottom: 10px;
        border-radius: 4px;
        background-color: #FFFFFF;
        .box {
          h1 {
            width: fit-content;
            // line-height: 32px;
            // font-size: 20px;
            line-height: 29px;
            font-size: 18px;
            font-family: Source Han Sans CN Medium;
            font-weight: 500;
            color: #242424;
            text-align: center;
            border-radius: 13px;
            background-color: transparent;
            margin: 0px auto;
            padding: 0 15px;
          }
          p {
            width: 100%;
            line-height: 20px;
            font-size: 12px;
            font-family: Source Han Sans CN Medium;
            font-weight: 300;
            color: #999999;
            text-align: right;
            padding-right: 5px;
          }
        }
      }
      .titem.act {
        h1 {
          color: #FFFFFF;
          background-color: #FF0000;
        }
        p {
          color: #FF0000;
        }
      }
      table {
        height: 100%;
        margin: 0 -10px;
        margin-bottom: 10px;
        tr:nth-of-type(1) td .box{
          padding: 5px 5px 0px 5px;
          // padding-top: 5px;
        }
        tr:nth-last-of-type(1) td .box{
          padding-bottom: 5px;
        }
        tr {
          height: 100%;
          td {
            height: 100%;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            .box {
              width: 100%;
              height: 100%;
              border-radius: 4px;
              // background-color: #FFFFFF;
              h1 {
                width: fit-content;
                max-width: 120px;
                padding: 0 10px;
                text-align: left;
              }
            }
          }
        }
      }
      table tr td, .titem {
        margin-bottom: 10px;
        border-radius: 4px;
        .box {
          h1 {
            // width: fit-content;
            height: 100%;
            word-break: break-all;
            hyphens: auto;
            line-height: 29px;
            font-size: 18px;
            // line-height: 32px;
            // font-size: 20px;
            font-family: Source Han Sans CN Medium;
            font-weight: 500;
            color: #242424;
            border-radius: 13px;
            background-color: transparent;
            margin: 0px auto;
          }
          p {
            width: 100%;
            line-height: 20px;
            font-size: 12px;
            font-family: Source Han Sans CN Medium;
            font-weight: 300;
            color: #999999;
            text-align: right;
            padding-right: 5px;
          }
        }
      }
      table tr td.act, .titem.act {
        h1 {
          color: #FFFFFF;
          background-color: #FF0000;
        }
        p {
          color: #FF0000;
        }
      }
    }
    .groups {
      width: 100%;
      margin-top: 10px;
      background: #ffffff;
      .group {
        width: 100%;
        .group_head.act {
          h1, p:not(.act) {
            color: #2630b2;
            // color: #3332C8;
          }
        }
        .group_head {
          width: 100%;
          // padding: 17px 20px;
          padding: 20px;
          padding-right: 32px;
          border: 2px solid #fafbfc;
          background: #ffffff;
          position: relative;
          z-index: 20;
          cursor: pointer;
          h1 {
            line-height: 18px;
            font-size: 18px;
            font-family: Source Han Sans CN Medium;
            font-weight: 500;
            color: #999999;
          }
          h2 {
            line-height: 16px;
            font-size: 16px;
            font-family: Source Han Sans CN Medium;
            font-weight: 300;
            color: #999999;
            bottom: 20px;
            right: 100px;
            position: absolute;
          }
          p {
            line-height: 13px;
            font-size: 13px;
            font-family: PingFang SC Medium;
            font-weight: 500;
            color: #cccccc;
            padding: 5px;
            bottom: 15px;
            // bottom: 20px;
            right: 40px;
            position: absolute;
            border-radius: 6px;
          }
          p.act {
            color: #FFFFFF;
            background: #FF0000;
          }
          span {
            width: 9px;
            height: 9px;
            // top: 17px;
            top: 23px;
            right: 20px;
            position: absolute;
            display: inline-block;
            border-top: 2px solid #2630b2;
            border-left: 2px solid #2630b2;
            transform: rotate(-135deg);
          }
          span.open,
          span.close {
            opacity: 1;
          }
          span.open {
            // top: 21px;
            top: 28px;
            transform: rotate(45deg);
          }
          span.close {
            border-color: #999999;
          }
        }
        .group_detail {
          width: 100%;
          .group_course {
            width: 100%;
            padding: 15px 20px;
            background: #fafdff;
            border: 2px solid #ffffff;
            position: relative;
            h1 {
              margin-top: 10px;
              margin-bottom: 0;
              line-height: 30px;
              font-size: 18px;
              font-family: PingFang SC Medium;
              font-weight: 500;
              color: #3B44B9;
            }
            p {
              margin-bottom: 0;
              margin-top: 10px;
              line-height: 16px;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #999999;
              span {
                color: #3B44B9;
              }
            }
            h2, >span {
              font-family: PingFang SC Medium;
              font-weight: 300;
            }
            h2 {
              line-height: 15px;
              font-size: 15px;
              color: #3B44B9;
            }
            >span {
              line-height: 13px;
              font-size: 13px;
              top: 15px;
              right: 20px;
              position: absolute;
            }
            >span.act {
              color: #FF0000;
            }
          }
        }
      }
    }
  }
}
@media (max-width:767px) {
  .live-schedule {
    min-height: 662px;
    padding: 0;
    padding-bottom: 60px;
  }
}
</style>